<template>
    <div class="container">
        <h1>{{$t('adminLanding.heading')}}</h1>
        <p>{{$t('adminLanding.subtitle')}}</p>
    </div>
</template>

<script>
export default {
    name: 'Index'
}
</script>

<i18n>
{
    "cz": {
        "adminLanding": {
            "heading": "Administrace",
            "subtitle": "Pokračujte výběrem požadované agendy z hlavního menu."
        }
    },
    "en": {
    }
}
</i18n>
